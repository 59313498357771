<template>
  <div>
    <div class="dashboard-navbar">
      <div class="dashboard-title">
        <i class="el-icon-menu"></i>
        {{ data.name }}
      </div>

      <div class="dashboard-tools">
        <el-button size="small" icon="el-icon-data-analysis" @click="addChart">
          <span>
            {{ $t("handle.add") }}
          </span>
        </el-button>

        <el-button size="small" icon="el-icon-folder-add" @click="patchPanel" :loading="patchLoading">
          <span>
            {{ $t("handle.save") }}
          </span>
        </el-button>

        <el-button size="small" icon="el-icon-setting" @click="toJSONModel">
          <span>
            {{ $t("handle.batchImport") }}
          </span>
        </el-button>

        <chart-tool
          :visibleObj="{ timeRange: true, interval: true }"
          :interval.sync="chartInterval"
          :timeRange.sync="timeRange"
        />
      </div>
    </div>

    <div class="loading-pre-container" v-loading="loading">
      <div v-if="!loading">
        <grid-layout
          :layout.sync="layoutData"
          :col-num="12"
          :row-height="30"
          :is-draggable="true"
          :is-resizable="true"
          :is-mirrored="false"
          :vertical-compact="true"
          :margin="[10, 10]"
          :use-css-transforms="false"
        >
          <grid-item
            v-for="item in layoutData"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item.i"
            :dragAllowFrom="'.panel-title'"
            :dragIgnoreFrom="'.el-dropdown-link'"
          >
            <div class="panel-wrapper" v-if="item.isNew" :class="{ new: item.isNew }">
              <div class="panel-title">{{ item.title }}</div>
              <el-button type="primary" size="small" @click="toConfigureNewPanel" icon="el-icon-setting">
                To configure
              </el-button>

              <el-button type="danger" size="small" icon="el-icon-delete" @click="deleteNewPanel">
                {{ $t("handle.delete") }}
              </el-button>
            </div>

            <div class="panel-wrapper" v-else>
              <div class="panel-title">
                <div class="dropdown-container">
                  <el-dropdown trigger="click" size="small">
                    <span class="el-dropdown-link">
                      {{ item.title }}
                      <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-edit-outline" @click.native="editPanel(item.uuid)">
                        {{ $t("handle.edit") }}
                      </el-dropdown-item>
                      <el-dropdown-item divided icon="el-icon-delete" @click.native="delelePanel(item.uuid)">
                        {{ $t("handle.delete") }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>

              <div class="panel-chart">
                <chart-panel
                  :timeRange="timeRange"
                  :ref="'chart-panel-' + item.i"
                  :metric-info="item"
                  :auto-refresh="chartInterval"
                />
              </div>
            </div>
          </grid-item>
        </grid-layout>
      </div>
    </div>

    <el-dialog :title="$t('handle.batchImport')" :visible.sync="importDialogVisible" top="30px">
      <el-alert :title="$t('prompt')" type="info" show-icon>
        <div>
          <div class="title" style="margin-bottom: 2px;">
            {{ $t("importPanelTips1") }}
          </div>
          <div class="title">
            {{ $t("importPanelTips2") }}
          </div>
        </div>
      </el-alert>

      <div class="select-provider">
        <span class="title">{{ $t("selectPrometheusOrigin") }}:</span>

        <el-select v-model="provider" size="small">
          <el-option
            v-for="item in providers"
            :key="item.provideruuid"
            :label="item.cluster"
            :value="item.provideruuid"
            :disabled="item.status == 'Deny'"
          >
            <span style="float: left">{{ item.cluster }}</span>

            <span style="float: right;" class="status error" v-if="item.status === 'Deny'">
              {{ $t("effectDeny") }}
            </span>
            <span style="float: right;" class="status success" v-else>
              {{ $t("effectAllow") }}
            </span>
          </el-option>
        </el-select>
      </div>

      <editor v-model="jsonModel" @init="editorInit" lang="json" theme="cobalt" width="100%" height="400"></editor>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="importJSONModel" size="small" :loading="importLoading">
          {{ $t("handle.import") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDashboardDetail, deletePanel, patchPanel } from "api/dashboard";
import ChartTool from "@/components/ChartTool";
import chartTool from "@/mixins/chart-tool";
import VueGridLayout from "vue-grid-layout";
import ChartPanel from "@/pages/Detail/components/ChartPanel";
import { cloneDeep, includes } from "lodash";

export default {
  components: {
    ChartTool,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    ChartPanel,
    editor: require("vue2-ace-editor")
  },

  mixins: [chartTool],

  data() {
    return {
      loading: true,
      layoutData: [],
      updateLoading: false,
      patchLoading: false,
      importLoading: false,
      newFlag: false,
      importDialogVisible: false,
      data: {},
      jsonModel: "",
      provider: ""
    };
  },

  computed: {
    dashboard() {
      return this.$route.params.dashboard;
    },

    providers() {
      return this.$store.state.app.userInfo.providers;
    }
  },

  methods: {
    init() {
      this.loading = true;

      getDashboardDetail(this.dashboard).then(response => {
        if (response.code === 0) {
          this.data = response.data;
          this.layoutData = response.data.panels || [];

          if (this.layoutData.length > 0) {
            this.layoutData.forEach(item => (item.i = item.uuid));
          } else {
            this.newFlag = true;
            this.layoutData.unshift({
              x: 0,
              y: 0,
              w: 6,
              h: 6,
              i: this.layoutData.length + 1,
              title: "new_panel_title",
              isNew: true
            });
          }

          this.loading = false;
        }
      });
    },

    editorInit() {
      require("brace/ext/searchbox");
      require("brace/mode/json");
      require("brace/theme/cobalt");
    },

    toJSONModel() {
      this.importDialogVisible = true;

      let data = cloneDeep(this.layoutData);

      data.forEach(item => {
        this.$delete(item, "organizationUUID");
        this.$delete(item, "dashboardUUID");
        this.$delete(item, "vendor");
        this.$delete(item, "region");
        this.$delete(item, "cluster");
        this.$delete(item, "provider");
      });

      this.jsonModel = JSON.stringify(data, null, 2);
    },

    importJSONModel() {
      let panels = JSON.parse(this.jsonModel);

      let vendor, region, cluster;

      if (this.provider) {
        for (let item of this.providers) {
          if (item.provideruuid === this.provider) {
            vendor = item.kind;
            region = item.regionID;
            cluster = item.cluster;
            break;
          }
        }
      }

      let uniqueLayoutUUID = this.layoutData.map(item => item.uuid);

      panels.forEach(item => {
        if (!includes(uniqueLayoutUUID, item.uuid)) this.$delete(item, "uuid");

        this.$set(item, "organizationUUID", this.data.organizationUUID);
        this.$set(item, "dashboardUUID", this.data.uuid);
        this.$set(item, "vendor", vendor);
        this.$set(item, "region", region);
        this.$set(item, "cluster", cluster);
        this.$set(item, "provider", this.provider);
      });

      let data = cloneDeep(this.data);
      data.panels = panels;

      this.importLoading = true;
      patchPanel(this.dashboard, data).then(response => {
        if (response.code === 0) {
          this.importDialogVisible = false;
          this.init();
        }
        this.importLoading = false;
      });
    },

    editPanel(uuid) {
      this.$router.push({
        path: `/dashboard/${this.dashboard}/config`,
        query: {
          type: "edit",
          panel: uuid
        }
      });
    },

    delelePanel(uuid) {
      this.$confirm(this.$t("delete", []), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          deletePanel(this.dashboard, uuid).then(response => {
            if (response.code === 0) this.init();
          });
        })
        .catch(() => {});
    },

    addChart() {
      if (!this.newFlag) {
        this.newFlag = true;
        this.layoutData.unshift({
          x: 0,
          y: 0,
          w: 6,
          h: 6,
          i: this.layoutData.length + 1,
          title: "new_panel_title",
          isNew: true
        });
      }
    },

    toConfigureNewPanel() {
      this.$router.push({
        path: `/dashboard/${this.dashboard}/config`,
        query: {
          type: "add"
        }
      });
    },

    deleteNewPanel() {
      this.layoutData.splice(0, 1);
      this.newFlag = false;
    },

    patchPanel() {
      let data = cloneDeep(this.data);
      data.panels = this.layoutData;

      this.patchLoading = true;
      patchPanel(this.dashboard, data).then(response => {
        if (response.code === 0) {
          this.$notify({
            title: "Success",
            message: "Success",
            type: "success"
          });
        }
        this.patchLoading = false;
      });
    }
  },

  mounted() {
    this.init();
    if (this.providers.length > 0) {
      for (let item of this.providers) {
        if (item.status != "Deny") {
          this.provider = item.provideruuid;
          break;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.select-provider {
  @include flex(flex-start);
  margin: 10px 0;

  .select-provider-text {
    font-size: 12px;
  }
  .el-select {
    margin-right: 6px;
  }
}

.vue-grid-item.vue-grid-placeholder {
  background: $color-primary !important;
}

.dashboard-navbar {
  @include flex(space-between, flex-start);

  .dashboard-title {
    font-size: 18px;
  }

  .dashboard-tools {
    @include flex(flex-start);

    .chart-tools {
      margin-bottom: 0;
    }
  }
}

.chart-handle-buttons {
  position: absolute;
  left: 0;
  top: 0;
}

.panel-wrapper {
  &.new {
    border-color: $color-primary;
    border-width: 2px;
    text-align: center;

    .el-button {
      margin-top: 30px;
    }
  }

  position: relative;
  height: 100%;
  background-color: #fff;
  border: 1px solid #dce1e6;
  border-radius: 4px;

  .panel-title {
    height: 28px;
    line-height: 28px;
    text-align: center;
    @include title(14px);
    transition: 0.2s all ease;

    &:hover {
      background-color: #f1f5f9;
    }

    .dropdown-container {
      max-width: 80%;
      margin: 0 auto;
      overflow: hidden;
    }

    .el-dropdown {
      max-width: 100%;
    }

    .el-dropdown-link {
      cursor: pointer;
      @include text-overflow();
    }
  }

  .panel-chart {
    height: calc(100% - 28px);
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
  }
}
</style>
